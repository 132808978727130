/* 
 * Copyright 2018-2021 Philipp Giebel <public@ambnet.biz>
 *
 * All rights reserved
 */

var loading = false;

$( document ).ready(function() {
  $(window).scroll(function() {
    if ( $(window).scrollTop() >= $(window).height()/2 ) {
      $('body').addClass('scrolled');
    } else if ( $(window).scrollTop() <= 10 ) {
      $('body').removeClass('scrolled');
    }
    if ( $(window).scrollTop() >= 60 ) {
      $('.container-hearthis').addClass('fixed');
    } else {
      $('.container-hearthis').removeClass('fixed');
    }
  }); // end window scroll
  if ( $(window).scrollTop() >= $(window).height()-60 ) {
    $('body').addClass('scrolled');
  } else {
    $('body').removeClass('scrolled');
  }
  $('a[href*=\\#]:not([href=\\#],[href*=\\#historyDetail],[href*=\\#privacy])').click(function(e) {
    if ( $(this).data('slide') !== false ) {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top
          }, 1000);
          return false;
        }
      }
    } else {
      e.preventDefault();
      $.ajax({
        url: link,
        cache: false,
      }).done(function( html ) {
        id = html.match(/hearthis:embed:id" content="(.*)"/)[1];
        ogtitle = html.match(/og:title" content="(.*)"/)[1];
        embedcontainer.html('<iframe scrolling="no" id="hearthis_at_track_'+id+'" width="100%" height="150" src="https://app.hearthis.at/embed/'+id+'/transparent_black/?hcolor=0056d6&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=1&css=" frameborder="0" allowtransparency allow="autoplay"><p>Listen to <a href="'+link+'" target="_blank">'+ogtitle+'</a> <span>by</span><a href="https://hearthis.at/stimpy/" target="_blank" >stimpy</a> <span>on</span> <a href="https://hearthis.at/" target="_blank">hearthis.at</a></p></iframe>');
        embedcontainer.addClass('playing');
      });
    }
  });
  $('.btn-imprint').unbind().on('click', function(e) {
    e.preventDefault();
    $.ajax({
      url: '/imprint.inc.html',
      method: 'GET'
    }).done(function( msg ) {
      $('.container-imprint').html(msg);
    });
  });
  const p = document.getElementsByClassName('parallax');
  new simpleParallax(p, {
    scale: 1.5,
  });
  const pd = document.getElementsByClassName('parallax-down');
  new simpleParallax(pd, {
    scale: 1.5,
    orientation: 'down'
  });
  $('.ajaxvideo').unbind().on( 'click', function(e) {
    e.preventDefault();
    $(this).parent().html('<iframe src="'+$(this).attr('href')+'?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
  });
  $('.ajaxmix').unbind().on( 'click', function(e) {
    e.preventDefault();
    embedcontainer = $(this).parent();
    $.ajax({
      url: "https://hearthis.at/stimpy/podcast.xml",
      cache: false,
      dataType: "xml"
    }).done(function( xml ) {
      link = $(xml).find('item').first().find("link").first().text().replace('http://', 'https://');
      $.ajax({
        url: link,
        cache: false,
      }).done(function( html ) {
        id = html.match(/hearthis:embed:id" content="(.*)"/)[1];
        ogtitle = html.match(/og:title" content="(.*)"/)[1];
        embedcontainer.html('<iframe scrolling="no" id="hearthis_at_track_'+id+'" width="100%" height="150" src="https://app.hearthis.at/embed/'+id+'/transparent_black/?hcolor=0056d6&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=1&css=" frameborder="0" allowtransparency allow="autoplay"><p>Listen to <a href="'+link+'" target="_blank">'+ogtitle+'</a> <span>by</span><a href="https://hearthis.at/stimpy/" target="_blank" >stimpy</a> <span>on</span> <a href="https://hearthis.at/" target="_blank">hearthis.at</a></p></iframe>');
        embedcontainer.addClass('playing');
      });
    });
  });

  $('.ajaxtrack').unbind().on( 'click', function(e) {
    e.preventDefault();
    thiscontainer = $(this);
    $.ajax({
      url: thiscontainer.attr('href')
    }).done(function( html ) {
      id = html.match(/hearthis:embed:id" content="(.*)"/)[1];
      ogtitle = html.match(/og:title" content="(.*)"/)[1];
      thiscontainer.parent().html('<iframe scrolling="no" id="hearthis_at_track_'+id+'" width="300" height="284" src="https://app.hearthis.at/embed/'+id+'/transparent_black/?hcolor=0056d6&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=1&css=" frameborder="0" allowtransparency allow="autoplay"><p>Listen to <a href="'+thiscontainer.attr('href')+'" target="_blank">'+ogtitle+'</a> <span>by</span><a href="https://hearthis.at/stimpy/" target="_blank" >stimpy</a> <span>on</span> <a href="https://hearthis.at/" target="_blank">hearthis.at</a></p></iframe>');
      thiscontainer.parent().addClass('playing');
    });
  });

  var now = new Date();
  var maxyear = now.getYear()-100+2000;
  var loading = false;

  for (i = maxyear; i >= 2005; i--) {
    if ( i < 2010 || i > 2011 ) {
      $('#plyear').append('<option>'+i+'</option>');
    }
  }

  $('#plyear').on('change', function (e, clickedIndex, isSelected, previousValue) {
    if ( $('#plyear').val() == 0 ) {
      $('#pllist').html('');
      $('#htplayer').html('');
      $('#playlist').html('');
    } else {
      if ( loading === false ) {
        loading = true;
        $.ajax({
          url: './ajax/playlists.ajax.php',
          dataType: 'json',
          data: { year: $('#plyear').val(), token: '303' },
          method: 'POST',
          success: function(content) {
            loading = false;
            $('#pllist').html('');
            content.forEach( function (show, index) {
              line = show[2].split(' ')[0] +' '+ show[1];
              if ( show[4] !== null ) {
                line = line +' ('+ show[4] +')';
              }
              $('#pllist').append('<li><a href="#'+ show[0] +'" data-htid="'+ show[5] +'">'+ line +'</a></li>');
            });
            $('#pllist a').on('click', showPlaylist);
          }
        });
      }
    }
  });
  setTimeout( function() {
    var d = new Date();
    $('#plyear').val( d.getYear()+1900 );
    $('#plyear').trigger('change');
    setTimeout( function() {
      if ( $('#pllist a').length > 0 ) {
        $('a[href="'+$('#pllist a').first()[0].hash+'"]').trigger('click');
      }
    }, 500 );
  }, 500);
  new WOW().init();
  $('[data-toggle="tooltip"]').tooltip();
});

function showPlaylist(e) {
  id = parseInt( e.target.hash.substring(1) );
  e.preventDefault();
  if ( loading === false ) {
    loading = true;
    $('#pllist a').removeClass('active');
    $('#'+id).addClass('active');
    $.ajax({
      url: './ajax/playlists.ajax.php',
      dataType: 'json',
      data: { showid: id, token: '303' },
      method: 'POST',
      success: function(content) {
        loading = false;
        $('#playlist-image').remove();
        $('#playlist').html('');
        $('#htplayer').html('');
        htid = $('#pllist a[href="#'+id+'"]').data('htid');
        if ( htid > 0 ) {
          $('#htplayer').html('<a href="https://app.hearthis.at/embed/'+htid+'/transparent_black/?hcolor=&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=1&css="><img src="img/hearthis.png" alt="click to listen on hearthis.at" /></a>');
          $('#htplayer a').on('click', function(e) {
            e.preventDefault();
            $('#htplayer').html('<iframe scrolling="no" id="hearthis_at_track_'+htid+'" width="100%" height="150" src="'+e.currentTarget.href+'" frameborder="0" allowtransparency allow="autoplay"></iframe>')
          });
        } else {
          $('#htplayer').html('<img src="img/hearthis-notfound.png" alt="no upload found :(" />');
        }
        if ( content.length > 0 ) {
          content.forEach( function (track, index) {
            $('#playlist').append('<li>'+ track[3] +' - '+ track[4] +'</li>');
          });
        } else {
          $('#playlist').append('<li>No playlist found, sorry...</li>');
        }
      }
    });
  }
}